import {latLng, icon} from "leaflet";
import {LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon} from "vue2-leaflet";
import mainSelect from '@/components/main-select/index.vue'
import secondaryButton from '@/components/buttons/secondary-button/index.vue'
import {mapActions, mapGetters} from "vuex";
import {ModelListSelect} from "vue-search-select";
import Breadcrumbs from '@/components/breadcrumbs/index.vue';
import uniqBy from 'lodash/uniqBy';

export default {
  name: "partners",
  components: {
    secondaryButton,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    mainSelect,
    LIcon,
    ModelListSelect,
    Breadcrumbs
  },
  data() {
    return {
      selectedState: '',
      professionalList: [],
      searchCountryTitle: '',
      cityCollection: [],
      stateCollection: [],
      allPartners: [],
      payload: {
        country: 'UA',
        state: '',
        city: ''
      },
      selectedCountry: {
        category: '',
        type: {
          name: 'Киев',
          key: 'default',
          lat: 47.9118958,
          lon: 33.4021624,
        }
      },
      types: [
        {
          area: 'Днепропетровская область',
          options: [{
            area: 'Днепропетровская область',
            key: 'default',
            name: 'Частная практика',
            address: 'Кривой Рог ул. Отто Брозовского',
            city: 'Кривой Рог',
            fio: 'Лилия Александровна Токарская',
            lat: 47.9118958,
            lon: 33.4021624,
          }],
        },
        {
          area: 'Житомирская область',
          options: [
            {
              area: 'Житомирская область',
              key: 'default',
              name: 'Частная практика',
              address: 'Ул. И. Кочерги, 13/1',
              city: 'Житомир',
              fio: 'Татьяна Викторовна Гончарова',
              lat: 50.2542435,
              lon: 28.6692858,
            },
            {
              area: 'Житомирская область',
              key: 'default',
              name: 'Частная практика',
              address: ' ',
              city: 'Новгоград-Волинський',
              fio: 'Татьяна Алексеевна Осипенко',
              lat: 50.5832953,
              lon: 27.5852133,
            }],
        },
        {
          area: 'Закарпатская область',
          options: [
            {
              area: 'Закарпатская область',
              key: 'default',
              name: 'Частная практика',
              address: '',
              fio: 'Татьяна Михайловна Тумакова',
              city: 'Ужгород',
              lat: 48.6198474,
              lon: 22.2945934,
            },
          ],
        },


      ],
      userLocation: {},
      zoom: 43,
      center: latLng(50.4533677, 30.3866232),
      // url: 'https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      url: 'https://tile3.maps.2gis.com/tiles?x={x}&y={y}&z={z}',
      // attribution:
      //   '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // withPopup: latLng(47.41322, -1.219482),
      icon: icon({
        iconUrl: require('../../assets/img/marker/marker.svg'),
        shadowUrl: require('../../assets/img/marker/marker-shadow.png'),
        iconSize: [64, 64],
        iconAnchor: [33, 30],
        shadowAnchor: [14, 14],
      }),
      withPopup: latLng(50.453652, 30.386801),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 3
      },
      showMap: true
    };
  },

  created() {
    this.fetchTowns('')
    this.getPartner({
      country: 'UA',
      state: '',
      city: ''
    }).then(() => {
      // this.allPartners = this.partnersList

      this.partnersList.forEach(el => {
        let state = this.states.find(state => state.code === el.state)
        if (!this.allPartners.length) {
          this.allPartners.push({state: state.name, values: [el]})
        } else {
          this.allPartners.forEach(elem => {
            if (elem.state === state.name) {
              elem.values.push(el)
            } else {
              this.allPartners.push({state: state.name, values: [el]})
            }
          })
        }

        console.log(this.citiesList, 'citiesList');
        this.allPartners.forEach(el => {

          el.values.forEach(city => {
            let town = this.citiesList.find(c => c.city === city.city)
            console.log(town, 'town');
          })
        })

        // this.states.forEach(elem => {
        //   if (el.state === elem.code) {
        //     console.log(elem.name);
        //     if (!this.allPartners.length) {
        //       this.allPartners.push({state: elem.name, values: [el]})
        //     }
        //     if (this.allPartners.length) {
        //       this.allPartners.forEach(partner => {
        //         if (partner.state === elem.name) {
        //           partner.values.push(el)
        //         } else {
        //           this.allPartners.push({state: elem.name, values: [el]})
        //         }
        //       })
        //     }
        //
        //   }
        // })
      })
      let uniqFilters = uniqBy(this.allPartners, 'email')
      console.log(this.allPartners, 'allPartners');

      // this.allPartners.forEach((el,idx) => {
      //   this.states.forEach(elem => {
      //     if (el.state === elem.code) {
      //       console.log(idx,1);
      //       return el.state = elem.name
      //     }
      //   })
      //
      // })
    })

  },
  watch: {
    'partnersList': function () {

    },
    'payload.state'() {
      if (this.payload.state.length >= 2) {
        this.fetchTowns(this.payload).then(() => {
          this.cityCollection = this.citiesList
        })
      }

    }

  },
  mounted() {
    this.fetchState(this.payload.country).then(() => {
      this.stateCollection = this.states
    })
    this.withPopup = [
      latLng(this.selectedCountry.type['lat'], this.selectedCountry.type['lon']),
      latLng(50.453652, 30.386801),
      latLng(50.5832953, 27.5852133),
      latLng(48.6198474, 22.2945934),
    ]
    this.center = latLng(48.6006382, 30.8241912)
    this.zoom = 6

  },
  methods: {
    ...mapActions({
      fetchTowns: 'auth/GET_TOWNS',
      fetchState: 'auth/GET_STATE',
      getPartner: 'auth/GET_PARTNERS'
    }),
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    printSearchText(searchText) {
      if (searchText.length >= 2) {
        this.searchCountryTitle = searchText
      }
    },
    fatchPartner() {
      this.getPartner(this.payload).then(() => {
        this.professionalList = []
        this.partnersList.forEach(partner => {
          this.citiesList.forEach(el => {
            if (partner.city === el.code) {
              let obj = {}
              obj.cityName = el.name
              let newObj = Object.assign({}, obj, partner)
              this.professionalList.push(newObj)
            }
          })
        })

        this.professionalList.forEach(el => {
          this.states.forEach(elem => {
            if (el.state === elem.code) {
              this.selectedState = elem.name
            }
          })

        })
      })

    }

  },
  computed: {
    ...mapGetters({
      citiesList: 'auth/townsRegistration',
      states: 'auth/state',
      partner: 'contacts/partner',
      partnersList: 'auth/partnersList'
    }),
  }
};
